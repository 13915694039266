<template>
    <LiefengModal
        title="修改子平台"
        :value="controlForm.formFlag"
        :fullscreen="true"
        :width="600"
        :height="600"
        @input="closeModal"
    >
      <template v-slot:contentarea>

        <div class="container">
          <div class="menu">
            <Menu theme="light" :active-name="controlForm.showMenu" @on-select="activeMenu">
              <MenuItem name="baseInfo">
                <Icon type="ios-information-circle" />平台配置信息
              </MenuItem>
              <MenuItem name="authInfo">
                <Icon type="ios-settings" />授权信息
              </MenuItem>
            </Menu>
          </div>
          <div class="content">
            <Form
                :model="formData"
                :label-colon="true"
                :label-width="120"
                v-show="controlForm.showMenu == 'baseInfo'"
                ref="form"
            >
              <FormItem label="子平台名称" prop="oemName">
                <span slot="label" class="validate">子平台名称:</span>
                <Input style="width:300px" v-model.trim="formData.oemName" :maxlength="50" placeholder="请填写子平台名称"></Input>
              </FormItem>
              <FormItem label="子平台编码" prop="oemCode">
                <span slot="label" class="validate">子平台编码:</span>
                <Input style="width:300px" v-model.trim="formData.oemCode" :maxlength="20" placeholder="不能大于20位"></Input>
              </FormItem>
              <FormItem label="子平台简称" prop="tenantName">
                <Input style="width:300px" v-model.trim="formData.tenantName" :maxlength="50" placeholder="请填写子平台简称"></Input>
              </FormItem>
              <FormItem label="版权信息">
                <Input style="width:300px" v-model.trim="formData.extra.copyRightInfo" :maxlength="50"
                       placeholder="请填写版权信息"></Input>
              </FormItem>
              <FormItem label="服务器地址" prop="serverAddr">
                <Input style="width:300px" v-model.trim="formData.extra.serverAddr" :maxlength="50"
                       placeholder="请填写服务器地址"></Input>
              </FormItem>
              <FormItem label="域名" prop="domain">
                <Input style="width:300px" v-model.trim="formData.extra.domain" :maxlength="50"
                       placeholder="请填写域名"></Input>
              </FormItem>
              <FormItem style="display:inline-block;width:220px"> 
                <p slot="label">Logo上传:</p>
                <LiefengUpload v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" ref="logoUpload" :defaultList='controlForm.defaultListLogo' :showView="true"></LiefengUpload>
              </FormItem>
              <span style="color: #c4c4c4;line-height:80px">说明：450 x 90px</span>
              <br>
              <FormItem style="display:inline-block;width:220px"> 
                <span slot="label">登陆Logo上传:</span>
                <LiefengUpload v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" ref="loginUpload" :defaultList='controlForm.defaultListLoginLogo' :showView="true"></LiefengUpload>
              </FormItem>
              <span style="color: #c4c4c4;line-height:80px">说明：140 x 140px</span>
              <br>
              <FormItem style="display:inline-block;width:220px"> 
                <span slot="label">登陆页背景:</span>
                <LiefengUpload v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" ref="bgUpload" :defaultList='controlForm.defaultListLoginBackground' :showView="true"></LiefengUpload>
              </FormItem>
              <span style="color: #c4c4c4;line-height:80px">说明：1920 x 980px最佳</span>
            </Form>
            <Form
                :model="formData"
                :label-colon="true"
                :label-width="100"
                v-show="controlForm.showMenu == 'authInfo'"
                ref="form"
            >
              <FormItem label="使用日期" prop="twoDate">
                <DatePicker
                    type="daterange"
                    format="yyyy-MM-dd"
                    v-model="formData.twoDate"
                    placeholder="请选择使用起止日期"
                    ></DatePicker>
              </FormItem>
              <FormItem label="状态" prop="status" :key='new Date().getTime()'>
                <Select v-model="formData.status" style="width:200px;text-align:left;margin-right:10px" placeholder="请选择状态">
                  <Option v-for="(item,index) in controlForm.statusList" :value="item.dictKey" :key="index">{{
                      item.dictValue
                    }}
                  </Option>
                </Select>
              </FormItem>
            </Form>
          </div>
        </div>

      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
        <Button type="primary" @click="saveData">确定</Button>
      </template>
    </LiefengModal>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import LiefengUpload from '@/components/LiefengUpload'

export default {
  components: {LiefengModal, LiefengUpload},

  data() {
    return {
      showImage: true,
      controlForm: {
        defaultListLogo: [],
        defaultListLoginLogo: [],
        defaultListLoginBackground: [],
        validateArr: [
          {
            name: 'oemName',
            value: '子平台名称不能为空！',
          },
          {
            name: 'oemCode',
            value: '子平台编码不能为空！',
          },
          {
            name: 'twoDate',
            value: '使用日期不能为空！'
          }
        ],
        id: '1',
        showMenu: 'baseInfo',
        activeMenu: '1',
        formFlag: false,
        timer: '',
        statusList: [{'dictKey': '1', 'dictValue': '正常'}, {'dictKey': '2', 'dictValue': '停止'}],
      },
      formData: {
        tenantId: '',
        oemName: '',
        oemCode: '',
        status: '',
        startTime: '',
        endTime: '',
        tenantName: '',
        twoDate: [],
        extra: {
          copyRightInfo: '',
          serverAddr: '',
          domain: '',
          logo: '',
          loginLogo: '',
          loginBackground: ''
        }
      }
    }
  },
  methods:
      {
        deleteLogo() {
          this.formData.extra.logo = '';
        },
        //保存时验证表单必填
        validate() {
          var flag = true;
          for (let k in this.controlForm.validateArr) {
            if (k == this.controlForm.validateArr.length - 1 && !this.formData.twoDate[0]) {
              this.$Message["error"]({
                background: true,
                content: this.controlForm.validateArr[k].value,
              });
              flag = false;
            }
            if (this.formData[this.controlForm.validateArr[k].name] == "") {
              this.$Message["error"]({
                background: true,
                content: this.controlForm.validateArr[k].value,
              });
              flag = false;
            }
          }
          return flag;
        },
        activeMenu(menu) {
          this.controlForm.showMenu = menu;
        }
        ,
        getDetail(tenantId){
          this.$get('/auth/api/auth/pc/TenantApi/selectById',{
            tenantId
          }).then(res =>{
            if(res.code == 200){
              this.formData = res.data

              //使用日期回显
              this.formData.twoDate = [this.formData.startTime, this.formData.endTime];

              this.showImage = false
              this.$nextTick(()=>{
                this.showImage = true
                if (this.formData.extra.logo) {
                  this.controlForm.defaultListLogo = [{ name: "", url: this.formData.extra.logo }];
                }else {
                  this.controlForm.defaultListLogo = []
                }
                if (this.formData.extra.loginLogo) {
                  this.controlForm.defaultListLoginLogo= [{ name: "", url: this.formData.extra.loginLogo }];
                }else {
                  this.controlForm.defaultListLoginLogo = []
                }
                if (this.formData.extra.loginBackground) {
                  this.controlForm.defaultListLoginBackground = [{ name: "", url: this.formData.extra.loginBackground }];
                }else {
                  this.controlForm.defaultListLoginBackground = []
                }
              })
            }else{
              this.$Message.error({
                content:'获取子平台详情失败',
                background:true
              })
              return
            }
          })
        }
        ,
        saveData() {
          // var valid = this.validate();
          // if (valid) {
            if(!this.formData.oemName.trim()){
            this.$Message.warning({
              content: '平台配置信息-子平台名称为空',
              background: true
            });
            return
          }
          if(!this.formData.oemCode.trim()){
            this.$Message.warning({
              content: '平台配置信息-子平台编码为空',
              background: true
            });
            return
          }
          if(this.formData.twoDate.length == 0 || !this.formData.twoDate[0]){
            this.$Message.warning({
              content: '授权信息-使用日期为空',
              background: true
            });
            return
          }
                this.formData.extra.logo = this.$refs.logoUpload.uploadList[0] ?  this.$refs.logoUpload.uploadList[0].url : '';
                this.formData.extra.loginLogo = this.$refs.loginUpload.uploadList[0] ?  this.$refs.loginUpload.uploadList[0].url : '';
                this.formData.extra.loginBackground = this.$refs.bgUpload.uploadList[0] ?  this.$refs.bgUpload.uploadList[0].url : '';
                this.formData.startTime = this.$core.formatDate(new Date(this.formData.twoDate[0]),'yyyy-MM-dd')
                this.formData.endTime = this.$core.formatDate(new Date(this.formData.twoDate[1]),'yyyy-MM-dd')
                this.$post('/auth/api/auth/pc/TenantApi/update', {
                  ...this.formData,
                }).then(res => {
                  if (res.code == 200) {
                    this.$Message.success({
                      content: '修改成功',
                      background: true
                    });
                    this.closeModal();
                    this.$emit('resetBtn')
                  } else {
                    this.$Message.error({
                      content: res.desc,
                      background: true
                    })
                    return
                  }
                })
              // }
        },
        closeModal(flag) {
          //关闭弹窗
          this.controlForm.formFlag = flag;
          //清空表单
          Object.assign(this.$data.formData, this.$options.data().formData);
          //重置表单字段Form
          this.$refs['form'].resetFields();
          this.showImage = false;
          this.$nextTick(()=>{this.showImage = true;})
          this.controlForm.showMenu = "baseInfo"
          //父页面刷新列表
          // if(!flag){
          //   this.$emit('resetBtn')
          // }
        }
      }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 123px);
  /deep/.menu {
    height: 100%;
    .ivu-menu {
      height: 100%;
      width: 220px !important;
    }
  }
  .content {
    overflow: auto;
    padding-top: 20px;
    flex: 1;
    height: 100%;
  }
}
/deep/.ivu-tooltip-inner {
  max-width: 100%;
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.dateTime {
  width: 372px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .ivu-checkbox-wrapper {
    width: 50px;
    height: 40px;
    line-height: 40px;
    margin: 6px;
    text-align: center;
    &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
      background: #5cadff;
      color: #fff;
    }
    /deep/.ivu-checkbox {
      display: none;
    }
  }
}
.dateWeek {
  width: 550px;
  display: inline-flex;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: flex-start;
  .ivu-checkbox-wrapper {
    flex: 1;
    &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
      background: #5cadff;
      color: #fff;
    }
    /deep/.ivu-checkbox {
      display: none;
    }
  }
}
.tempContainer {
  /deep/.ivu-tabs-content {
    height: calc(100vh - 122px);
    .tempBox {
      height: calc(100% - 35px);
      overflow: auto;
      min-height: 200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      & > div {
        width: 32%;
        margin: 0.5%;
        .ivu-card-head {
          padding: 10px;
          text-align: center;
        }
        .ivu-card-body {
          padding: 10px;
          line-height: 30px;
          min-height: 142px;
          height: 100%;
          overflow: auto;
        }
      }
      .noList {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
/deep/.ivu-input[disabled],
/deep/.ivu-input-number-disabled,
/deep/.ivu-input-number-input[disabled],
/deep/fieldset[disabled] .ivu-input,
/deep/.ivu-checkbox-disabled .ivu-checkbox-inner,
/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner,
/deep/.ivu-select-disabled .ivu-select-selection,
/deep/.ivu-radio-disabled .ivu-radio-inner {
  background-color: #fff;
  color: #333;
}
.modelForm {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    cursor: not-allowed;
  }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

</style>